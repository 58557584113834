/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
import socialHead2 from "../../../images/Amigos-do-Bem---Header-Image.jpg";
import leftIcon from "../../../images/Left Button.svg";
import inline from "../../../images/Amigos-do-Bem---Page-Image.jpg";
const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="AMIGOS DO BEM| Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="AMIGOS DO BEM"
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "Amigos do Bem, a non-profit organisation, are one of the largest social projects in Brazil, assisting more than 75,000 people in the hinterland of Alagoas, Pernambuco, and Ceará.",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement "
        heading={[
          {
            children: [
              {
                text: "Transform lives through education and self-sustaining projects, capable of promoting local development and social inclusion, eradicating hunger and misery through:",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title: " 1. Education projects",
            heading: [],
          },
          {
            id: "",
            title: " 2.  Income generation ",
            heading: [],
          },
          {
            id: "",
            title:
              " 3. Providing access to water, housing and health services  ",
            heading: [],
          },
        ]}
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        detailsRTF={[
          {
            children: [
              {
                text: "In Brazil’s Northeastern Hinterland, thousands of people in isolated villages face hunger, thirst and a total lack of health structure. Amigos do Bem was a welfare assistance model that worked to sustainably change life conditions in these regions. After a large and fast paced growth where operations expanded to three Brazilian states and included farms, production plants, distribution centers, schools and aid and support installations, they became concerned about money loss, information leakage and product quality. As part of our Corporate Social Investment and mission to make a social impact, we worked with their team to deliver a risk evaluation, find ways to eliminate or mitigate risks, and create a roadmap to deliver improvements and improve Amigos dos Bem’s critical processes.",
              },
            ],
            type: "heading-three",
          },
        ]}
        articlewithContent="YES"
        Articletitle="Challenge faced by organisation "
        heading={[
          {
            children: [
              {
                text: "In Brazil’s Northeastern Hinterland, thousands of people in isolated villages face hunger, thirst and a total lack of health structure. Amigos do Bem was a welfare assistance model that worked to sustainably change life conditions in these regions. After a large and fast paced growth where operations expanded to three Brazilian states and included farms, production plants, distribution centers, schools and aid and support installations, they became concerned about money loss, information leakage and product quality. As part of our Corporate Social Investment and mission to make a social impact, we worked with their team to deliver a risk evaluation, find ways to eliminate or mitigate risks, and create a roadmap to deliver improvements and improve Amigos dos Bem’s critical processes.",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "1. What perceptions donors have of the organisation, especially in terms of trust, credibility and delivery of meaningful student outcomes",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title: "2. How to best engage donors and make them feel valued ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "To date, minimal studies and surveys have been conducted to have a comprehensive understanding of what donors think despite their high importance for supporting our client’s vision.",
        //     heading: [],
        //   },
        // ]}
      />
      {/* <ValuesCollection
        key=""
        title="Challenge faced by organisation"
        changeDesign="YES"
        styleclass="pt-5px"
        heading={[
          {
            children: [
              {
                text: "An important part of Schools Plus’ network is their donor base, who are essentially for supporting many of the projects to help schools in disadvantaged communities.  ",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "To help inform the future donor outreach strategy and overall organisational strategy, Schools Plus engaged PiP to conduct a survey to understand: ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "1. What perceptions donors have of the organisation, especially in terms of trust, credibility and delivery of meaningful student outcomes",
            heading: [],
          },
          {
            id: "",
            title: "2. How to best engage donors and make them feel valued ",
            heading: [],
          },
          {
            id: "",
            title:
             "To date, minimal studies and surveys have been conducted to have a comprehensive understanding of what donors think despite their high importance for supporting our client’s vision.",
            heading: [],
          },
        ]}
      /> */}
      <ValuesCollection
        key=""
        title="How we helped "
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        className="bg-light-blue"
        statements={[
          {
            id: "",
            title:
              "	Ran a risk evaluation across 80% of Amigos dos Bem’s activities to uncover risks to the core activities of the organisation.",
            heading: [],
          },
          {
            id: "",
            title:
              "	Reviewed their data management, fundraising, physical donations and planning and production processes for gaps and risks.",
            heading: [],
          },
          {
            id: "",
            title:
              "Identified 29 risks and classified them based on their impact to the organisation activities.",
            heading: [],
          },
        ]}
      />
      <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        // heading={[
        //   {
        //     children: [
        //       {
        //         text: "Refreshed donor outreach strategy so it is relevant, insightful, and based on data from 39 donors.",
        //       },
        //     ],
        //     type: "paragraph",
        //   },
        // ]}
        statements={[
          {
            id: "",
            title:
              "Worked closely with the Amigo do Bem’s team to design high-level initiatives to eliminate or mitigate 25 of the 29 identified risks.",
            heading: [],
          },
          {
            id: "",
            title:
              "Built an Implementation Roadmap and approach to deliver the improvements and implement mitigation plans.",
            heading: [],
          },
          {
            id: "",
            title:
              "Created a ‘wiring’ diagnosis of helpful suggestions to improve Amigos Do Bem’s critical processes and reduce money loss, information leakage and ensure the services they provide are of the highest quality.",
            heading: [],
          },
        ]}
      />
      <p className="col-span-12 col-start-2 text-center p-20px">
        To learn more or to contribute to this great organisation, please visit
        &nbsp;
        <b>
          <a href="https://www.amigosdobem.org/" className="text-dark-blue">
            https://www.amigosdobem.org/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
